<template>
  <v-card
    color="grey"
    class="lighten-3 elevation-0 rounded-lg pa-3"
  >
    <v-row
      :id="block_id"
      v-click-outside="triggerOnFocus"
    >
      <v-col cols="6">
        <label>
          Номер свидетельства
          <field-menu>
            <v-list>
              <v-list-item @click="disabled = !disabled">Ручное заполнение</v-list-item>
              <v-list-item @click="fillWithCustomsData">Заполнить данными из таможни</v-list-item>
              <v-list-item @click="clearRegNumA">Очистить регистрационный номер и сменить ID
                документа</v-list-item>
            </v-list>
          </field-menu>
        </label>
        <v-text-field
          v-model="registration_details.reg_num_a"
          outlined
          :background-color="disabled ? 'grey lighten-2' : 'white'"
          dense
          :disabled="disabled"
          hide-details="auto"
        />
      </v-col>

      <v-col cols="6">
        <label>
          Срок свидетельства
          <custom-date-picker
            v-model="registration_details.reg_date_a"
            :visited.sync="fields_been_visited"
            :has-changes.sync="hasChanges"
            background-color="grey lighten-2"
            dense
            :disabled="disabled"
          />
        </label>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import CustomDatePicker from "@/components/shared/custom-date-picker";
import FieldMenu from "@/components/documents/field-menu.vue";
export default {
  components: {
    CustomDatePicker,
    FieldMenu,
  },
  mixins: [blockAutoUpdate],
  data: () => ({
    block_id: "statistics-registration-details",
    disabled: true,
    registration_details: {
      declaration_id: null,
      reg_num_a: null,
      reg_date_a: null,
    },
  }),
  computed: {
    ...mapGetters({
      selected: "ensuring/getSelected",
    }),
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    },
  },
  methods: {
    uploadData() {
      const registration_details = this.convertEmptyStringsToNull(
        this.registration_details
      );
      return this.$store
        .dispatch("ensuring/uploadBlockData", {
          name: "registration_details",
          value: registration_details,
        })
        .then((res) => {
          this.disabled = true;
          return res;
        });
    },
    setFields() {
      const { registration_details } = this.selected || {};
      for (let key in registration_details) {
        if (key in this.registration_details) {
          this.registration_details[key] = registration_details[key];
        }
      }
    },
  },
};
</script>

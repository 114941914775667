<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-row
          class=" statistics-box"
        >
          <v-col cols="6">
            <v-row>
              <v-col
                cols="12"
                class="pb-0"
              >
                <v-tooltip
                  v-for="(btn, idx) in visibleActions"
                  :key="idx"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      class="mr-2"
                      min-width="36px"
                      max-width="36px"
                      v-bind="attrs"
                      :disabled="btn.loading"
                      :loading="btn.loading"
                      v-on="on"
                      @click="callFunction(btn.action, { action: btn.action })"
                    >
                      <v-icon :color="btn.color || ''">
                        {{ btn.icon }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ btn.name }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row
              :id="block_id"
              v-click-outside="triggerOnFocus"
            >
              <v-col
                cols="4"
                class="pr-0"
              >
                <v-text-field
                  ref="out_number"
                  v-model="about_declaration.out_number"
                  maxlength="50"
                  placeholder="Исx. номер"
                  :error="about_declaration.out_number && about_declaration.out_number.length > 50"
                  outlined
                  :background-color="isRegistered ? 'grey lighten-2' : 'white'"
                  dense
                  hide-details="auto"
                  :disabled="isRegistered"
                />
              </v-col>
              <v-col
                cols="3"
                class="pr-0"
              >
                <custom-date-picker
                  ref="declaration_date"
                  v-model="declaration_date"
                  :visited.sync="fields_been_visited"
                  :has-changes.sync="hasChanges"
                  :disabled="isRegistered"
                />
              </v-col>
              <v-col cols="5">
                <v-text-field
                  ref="correction_reg_num"
                  v-model="about_declaration.correction_reg_num"
                  placeholder="Предшеств. документ"
                  outlined
                  dense
                  hide-details="auto"
                  :disabled="isRegistered"
                  :background-color="isRegistered ? 'grey lighten-2' : 'white'"
                  @change="fillDocGuidByRegNum"
                />
              </v-col>
            </v-row>
          </v-col>
          <document-control
            v-if="!pinned.includes('controls')"
            source="ensuring/getControl"
            pin-name="controls"
            class="ml-2"
          >
            <template #action>
              <common-errors-action />
            </template>
          </document-control>
        </v-row>
        <component
          :is="activeComponent"
          :ref="getReferenceAsync"
          :show.sync="showComponent"
          :params="activeComponentParams"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {mapGetters} from "vuex";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import moment from "moment";
import CustomDatePicker from "@/components/shared/custom-date-picker";
import {
  displayControlResponse,
  downloadXml,
  getFilenameFromContentDisposition,
  getSignedDocument,
  getXmlString, performDocumentControl,
  sendToEclient,
  validateRequiredParams,
} from "@/helpers/documents";
import ToolboxMixin from "@/mixins/toolbox.mixin";
import DocumentControl from "@/components/shared/document-control.vue";
import CommonErrorsAction from "@/components/ensuring/form/controls/common-errors-action.vue";
import {about_declaration as onAboutDeclaration} from "@/events/statistics/control";
import {highlightField} from "@/helpers/control";

export default {
  components: {
    CommonErrorsAction, DocumentControl,
    CustomDatePicker,
  },
  mixins: [blockAutoUpdate, ToolboxMixin],
  data: () => ({
    block_id: "ensuring-about-declaration",
    namespace: "ensuring",
    selectedPto: "0200",
    actions: [
      {
        visible: true,
        icon: "mdi-upload",
        action: "sendDocument",
        name: "Отправить в ИСТО",
      },
      {
        visible: true,
        icon: "mdi-file-pdf-box",
        action: "downloadPdf",
        name: "Скачать PDF",
      },
      {
        visible: true,
        icon: "mdi-file-xml-box",
        action: "downloadXml",
        name: "Скачать XML",
      },
      {
        visible: true,
        icon: "mdi-content-copy",
        action: "copyDocument",
        name: "Копировать документ",
      },
      {
        visible: false,
        loading: false,
        icon: "",
        action: "showDocumentControlResponse",
        name: "Контроль FLK",
        component: () =>
          import("@/components/shared/document-control-response.vue"),
      },
      {
        visible: false,
        loading: false,
        icon: "",
        action: "showXsdControlResponse",
        name: "Контроль XSD",
        component: () =>
          import("@/components/documents/xsd-control-response.vue"),
      },
    ],
    about_declaration: {
      declaration_id: null,
      out_number: "",
      correction_reg_num: "",
    },
    declaration_date: null,
  }),
  computed: {
    ...mapGetters({
      selected: "ensuring/getSelected",
      requests: "ensuring/getRequests",
      user: "auth/getUser",
      pinned: "ui/getPinned",
    }),
    isRegistered() {
      return !!this.selected?.registration_details?.reg_num_a;
    },
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    },
  },
  created() {
    onAboutDeclaration.subscribe(this.onControl)
  },
  beforeDestroy() {
    onAboutDeclaration.unsubscribe()
  },
  methods: {
    onControl({path}){
      highlightField.call(this, {path})
    },
    validateRequiredParams,
    getXmlString,
    getSignedDocument,
    sendToEclient,
    performDocumentControl,
    displayControlResponse,
    sendDocument() {
      this.setLoading('sendDocument', true)
      this.performDocumentControl()
        .then(this.displayControlResponse)
        .then(this.validateRequiredParams)
        .then(this.getXmlString)
        .then(this.getSignedDocument)
        .then(this.sendToEclient)
        .catch(() => {})
        .finally(() => {
          this.setLoading('sendDocument', false)
        })
    },
    async fillDocGuidByRegNum() {
      await this.uploadData()
      this.$store.dispatch('ensuring/fillDocGuidByRegNum', this.about_declaration.declaration_id).catch((err) => {
        this.$error(err.response.data)
      })
    },
    resolveAction({type}) {
      this.$error(`${type} отсутстует`)
    },
    downloadXml() {
      this.$store.dispatch("ensuring/getXml", this.selected.id).then((res) => {
        downloadXml(res.data, getFilenameFromContentDisposition(res));
      });
    },
    uploadData() {
      let declaration_date;
      const to_pattern = "YYYY-MM-DDTHH:mm:ss";
      const from_pattern = "YYYY-MM-DD";
      if (this.declaration_date) {
        const datetime = `${this.declaration_date} 00:00:00`;
        declaration_date = moment(datetime, from_pattern).format(to_pattern);
      }
      const payload = {
        ...this.about_declaration,
        declaration_date,
      };
      const about_declaration = this.convertEmptyStringsToNull(payload);
      return this.$store.dispatch("ensuring/uploadBlockData", {
        name: "about_declaration",
        value: about_declaration,
      });
    },
    setFields() {
      const {about_declaration} = this.selected || {};
      for (let key in about_declaration) {
        if (key in this.about_declaration) {
          this.about_declaration[key] = about_declaration[key];
        }
      }
      if (about_declaration.declaration_date) {
        this.declaration_date = moment(
          about_declaration.declaration_date
        ).format("YYYY-MM-DD");
      }
    },
  },
};
</script>
